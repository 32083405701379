import React, { useEffect, useState } from "react";
import { ServiceAPI } from "../API/ServiceAPI";
import GridLoader from "../Loaders/GridLoader";
import parse from "html-react-parser";

const TypMass = () => {
  const [allServices, setAllServices] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ServiceAPI.getAll()
      .then((res) => {
        setAllServices(res.data.data.services.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container all-section">
        <div className="text-center section">
          <h3>Types of Massage</h3>
          <img classname="divider-img" src="./divider.png" alt="divider" />
        </div>

        {loading ? (
          <GridLoader />
        ) : (
          <div class="row">
            {allServices?.slice(0, 3).map((service) => {
              return (
                <div class="col-lg-4">
                  <div class="card typ-of-mass">
                    <div className="mass-typ-img">
                      <img src={imageBaseURL + "/" + service?.image} class="card-img-top" alt="..." />
                    </div>
                    <div class="card-body py-2 px-3 mess-typ-bdy">
                      <h4>
                        <b>{service?.name}</b>
                      </h4>
                      <p>
                        <span className="foot-txt">Rs {service?.price}</span>
                      </p>
                      <p>{parse(service?.description || "")}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default TypMass;

// style={{background:"var(--primary-color)",color:"var(--white-color)"}}
