import React, { useEffect, useState } from "react";
import { BranchAPI } from "../API/BranchAPI";
import parse from "html-react-parser";
import GridLoader from "../Loaders/GridLoader";

const Branches = () => {
  const [allBranches, setAllBranches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BranchAPI.getAll()
      .then((res) => {
        setAllBranches(res.data.data.branches.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="container all-section">
        <div className="text-center section">
          <h3>Branches</h3>
          <img classname="divider-img" src="/divider.png" alt="divider" />
        </div>

        {loading ? (
          <GridLoader />
        ) : (
          <div className="row">
            {allBranches?.map((branch) => (
              <div className="col" key={branch?.id}>
                <div class="card brch-sec">
                  <div className="top-txt">
                    <p>{branch?.branch_address}</p>
                  </div>
                  <iframe src={branch?.google_location} width="100%" height="300" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8 brch-para-txt">
                        {parse(branch?.branch_description || "")}
                        {/* <ul>
                       <li>Kathmandu Metropolitan City</li>
                       <li>Kathmandu District</li>
                       <li>Bagmati Province</li>
                     </ul> */}
                      </div>
                      <div className="col-4 text-end brch-para-txt">
                        <ul>
                          <li> {branch?.branch_address}</li>
                          <li>
                            <a href={`tel:${branch?.branch_phone_no}`}>{branch?.branch_phone_no}</a>
                          </li>
                          {/* <li>
                         {" "}
                         <button
                           type="button"
                           className="btn out-lin-btn mt-2"
                         >
                           Book Now
                         </button>
                       </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Branches;
