import React, { useEffect, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { StudioApartmentAPI } from "../API/StudioApartmentAPI";
import { lengthRequired, validateRequired } from "../helpers/validators";
import { fireErrorModal, fireSuccessModal } from "../providers/sweetalert";
import parse from "html-react-parser";

const initialState = {
  name: "",
  email: "",
  phone_no: "",
  origin_country: "",
  no_of_days: "",
  reason_to_stay: "",
};

const StudioApartment = () => {
  const [booking, setBooking] = useState(false);
  const [bookingData, setBookingData] = useState(initialState);
  const [amenities, setAmenities] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [coverImage, setCoverImage] = useState([]);
  const [coverImageURL, setCoverImageURL] = useState("");
  useEffect(() => {
    StudioApartmentAPI.getAll()
      .then((res) => {
        setAmenities(res.data.data.amenities.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    StudioApartmentAPI.getAll()
      .then((res) => {
        setImageURL(res.data.data.image_base_url);
      })
      .catch((e) => {
        console.log(e.message);
      });
    StudioApartmentAPI.get()
      .then((res) => {
        setCoverImage(res.data.data.studioApartment);
      })
      .catch((e) => {
        console.log(e.message);
      });
    StudioApartmentAPI.get()
      .then((res) => {
        setCoverImageURL(res.data.data.image_base_url);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const handleBookingData = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  const submitBookingData = (e) => {
    e.preventDefault();

    if (
      !validateRequired(bookingData, [
        { key: "name", message: "Please enter your name" },
        { key: "email", message: "Please enter your email" },
        { key: "phone_no", message: "Please enter your contact number" },
        { key: "origin_country", message: "Please enter your origin Country" },
        { key: "no_of_days", message: "Please enter your No of Days" },
        { key: "reason_to_stay", message: "Please enter your reason to Stay" },
      ])
    ) {
      return;
    }

    if (!lengthRequired(bookingData, [{ key: "phone_no", message: " contact number must be of 10 digits" }])) {
      return;
    }
    setBooking(true);
    StudioApartmentAPI.book(bookingData)
      .then(() => {
        console.log("Booking Successfull");
        fireSuccessModal("Your appointment has been booked successfully !", "Thank You");
        setBookingData(initialState);
        setBooking(false);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal("Failed to book due to some error", "Error");

        setBooking(false);
      });
  };

  const [sliderRef] = useKeenSlider({
    breakpoints: {
      "(min-width: 200px)": {
        slides: { perView: 1, spacing: 15 },
      },
      "(min-width: 400px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(min-width: 550px)": {
        slides: { perView: 2, spacing: 15 },
      },
      "(min-width: 850px)": {
        slides: { perView: 3, spacing: 15 },
      },
      "(min-width: 1150px)": {
        slides: { perView: 3, spacing: 15 },
      },
      "(min-width: 1350px)": {
        slides: { perView: 3, spacing: 15 },
      },
      "(min-width: 1650px)": {
        slides: { perView: 4, spacing: 15 },
      },
    },
  });
  return (
    <div className="container mb-5">
      <div className="studio-img">
        <img src={`${coverImageURL}/${coverImage.cover_image}`} alt={coverImage.cover_image_alt} />
      </div>
      <div className="text-center mb-5">
        <button style={{ border: "0", backgroundColor: "white" }} className="text-center">
          <img style={{ width: "100%" }} src="line.png" alt="line" />
          <span className="caption">Rs {coverImage.price}</span>
        </button>
      </div>
      <div className="text-center">
        <h1 className="text text-center">
          Seeing Hands <span style={{ color: "#CD0000" }}>Apartment </span>
        </h1>
        <img style={{ width: "45%", height: "100%" }} src="line.png" alt="line" />
      </div>
      <div className="blog-Description-text mt-3">
        <p>{parse(coverImage.description || " ")}</p>
      </div>
      <div className="text-center">
        <h1 className="text text-center">
          <span style={{ color: "#CD0000" }}>Amenities </span>
        </h1>
        <img style={{ width: "45%", height: "100%" }} src="line.png" alt="line" />
      </div>
      <div className="mt-4">
        {amenities.length > 0 && (
          <div ref={sliderRef} className="keen-slider">
            {amenities.map((item) => (
              <div className="keen-slider__slide number-slide1">
                <div>
                  <h5 className="text-center">{item.title}</h5>
                </div>
                <div
                  style={{
                    height: "283px",
                    textAlign: "center",
                  }}>
                  <img
                    style={{
                      borderRadius: "10px",
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    src={`${imageURL}/${item?.image}`}
                    className="mb-3 img-fluid"
                    alt="pic"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className=" row " style={{ justifyContent: "space-between" }}>
        <form className="mt-5 col-lg-6 col-sm-12 col-md-12">
          <div className="text-center">
            <h1 className="text text-center">
              Get In <span style={{ color: "#CD0000" }}> Touch</span>
            </h1>
            <img style={{ width: "70%" }} src="/line.png" alt="line" />
          </div>
          <fieldset className="mt-4 ">
            <div>
              <div className="bookPlace mb-3">
                <input type="text" className="form-control" id="floatingInput" placeholder="Name" name="name" value={bookingData?.name} onChange={handleBookingData} />
              </div>
              <div className="bookPlace mb-3 row">
                <div className="col-6">
                  <input type="text" className="form-control " id="floatingInput" placeholder="E-mail" name="email" value={bookingData?.email} onChange={handleBookingData} />
                </div>
                <div className="col-6">
                  <input type="number" className="form-control " id="floatingInput" placeholder="Phone Number" name="phone_no" value={bookingData?.phone_no} onChange={handleBookingData} />
                </div>
              </div>
              <div className="bookPlace mb-3 row">
                <div className="col-6">
                  <input type="text" className="form-control " id="floatingInput" placeholder="Origin Country" name="origin_country" value={bookingData?.origin_country} onChange={handleBookingData} />
                </div>
                <div className="col-6">
                  <input type="number" className="form-control " id="floatingInput" placeholder="Number Of Days" name="no_of_days" value={bookingData?.no_of_days} onChange={handleBookingData} />
                </div>
              </div>
              <div className="bookPlace  mb-3">
                <textarea rows="8" cols="40" type="text" className="form-control" name="reason_to_stay" id="floatingInput" placeholder="Reason to Stay" value={bookingData?.reason_to_stay} onChange={handleBookingData} />
              </div>
            </div>

            <div className="d-grid">
              <button style={{ backgroundColor: "#1c1b9b" }} type="submit" className=" btn btn-primary" onClick={submitBookingData} disabled={booking}>
                {booking ? "Booking" : "Book"}
              </button>
            </div>
          </fieldset>
        </form>
        <div className=" mt-4 col-lg-6 col-md-12 col-sm-12 studio-img2">
          <img src="main.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default StudioApartment;
