import axios from "axios";

export const StudioApartmentAPI = {
  book: function (data) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/apartmentBooking/create-booking`,
      data: data,
    });
  },

  getAll: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/amenities/get-all`,
      params: {
        limit: limit,
      },
    });
  },
  get: function () {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/studioApartment/get-apartment`,
    });
  },
};
