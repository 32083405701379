import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ContactLoader = () => {
  return (
    <div>
      <div className="row gy-4">
        <div className="col-12 my-3">
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
          <Skeleton width={"100%"} />
        </div>
      </div>
    </div>
  );
};
