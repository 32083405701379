// import { fireErrorAlert } from "../Providers/SweetAlert";

import { fireErrorToaster } from "../providers/sweetalert";

export const validateRequired = (formState, arrayOfRequiredKey) => {
  for (let i = 0; i < arrayOfRequiredKey.length; i++) {
    if (
      formState[arrayOfRequiredKey[i]["key"]] === "" ||
      formState[arrayOfRequiredKey[i]["key"]] === null
    ) {
      fireErrorToaster(`${arrayOfRequiredKey[i].message} `);
      //   console.log(`${arrayOfRequiredKey[i]} cannot be empty`);

      return false;
    }
  }

  return true;
};

export const lengthRequired = (formState, arrayOfRequiredKey) => {
  for (let i = 0; i < arrayOfRequiredKey.length; i++) {
    if (formState[arrayOfRequiredKey[i]["key"].length] !== 10) {
      fireErrorToaster(`${arrayOfRequiredKey[i].message}`);
      return false;
    }
  }

  return true;
};
