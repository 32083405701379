import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GalleryAPI } from "../API/GalleryAPI";
import { SRLWrapper } from "simple-react-lightbox";
import GridLoader from "../Loaders/GridLoader";

const Gallery = () => {
  const [allImages, setAllImages] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    GalleryAPI.getAllImage()
      .then((res) => {
        setAllImages(res.data.data.images.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="container all-section">
        <div className="text-center section">
          <h3>Gallery</h3>
          <img classname="divider-img" src="./divider.png" alt="divider" />
        </div>

        {loading ? (
          <GridLoader item={4} />
        ) : (
          <SRLWrapper>
            <div class="row g-4">
              {allImages.slice(0, 8)?.map((image) => (
                <div class="col-lg-3 col-md-4 text-center" key={image?.id}>
                  <div className="gallery-imgs">
                    <img src={imageBaseURL + "/" + image?.image_name} alt={image?.image_alt} />
                  </div>
                </div>
              ))}

              <div className="text-center">
                <button type="button" class="btn out-lin-btn" onClick={() => navigate("/imageGallery")}>
                  See More
                </button>
              </div>
            </div>
          </SRLWrapper>
        )}
      </div>
    </>
  );
};

export default Gallery;
