import axios from "axios";

export const BranchAPI = {
  getAll: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/branch/get`,
      params: {
        limit: limit,
      },
    });
  },
};
