import axios from "axios";

export const BlogAPI = {
  getAll: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/blog/get`,
      params: {
        limit: limit,
      },
    });
  },

  getSingle: function (id) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/blog/get/${id}`,
    });
  },
};
