import React, { useEffect, useState } from "react";
import { BlogAPI } from "../../API/BlogAPI";
import parse from "html-react-parser";
import GridLoader from "../../Loaders/GridLoader";
import { Link } from "react-router-dom";
const Blog = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    BlogAPI.getAll()
      .then((res) => {
        setAllBlogs(res.data.data.blogs.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mb-5 mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          <span style={{ color: "#CD0000" }}> Blogs</span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </div>

      {loading ? (
        <GridLoader row={2} />
      ) : (
        <div className="g-3 row mt-5">
          {allBlogs?.map((blog) => (
            <div class="col-lg-4" key={blog?.id}>
              <div className="card" style={{ width: "100%" }}>
                <img src={imageBaseURL + "/" + blog?.image} class="card-img-top" alt={blog?.image_alt} />
                <div class="card-body">
                  <h4 class="card-text">{blog?.title}</h4>
                  <h5 class="card-text txt-sec">{parse(blog?.summary || "")}</h5>
                </div>

                <div className="card-body text-end">
                  <Link to={`/blog/${blog?.id}`} className="card-link  blog-link" style={{ textDecoration: "none", color: "#CD0000" }}>
                    Read More <span className="fa fa-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;
