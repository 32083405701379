import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { AboutUsAPI } from "../API/AboutUsApi";
import parse from "html-react-parser";

const About = () => {
  const [about, setAbout] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AboutUsAPI.get()
      .then((res) => {
        setAbout(res.data.data.about);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          About <span style={{ color: "#CD0000" }}> Seeing Hands</span>
        </h1>
        <img style={{ width: "45%", height: "100%" }} src="line.png" alt="line" />
      </div>
      <div className="about-img mt-5">{loading ? <Skeleton width={"100%"} height={"100%"} /> : <img src={imageBaseURL + "/" + about?.image} alt="img1" />}</div>
      <div className="mt-5 text-1">
        <p>{parse(about?.description || "")}</p>
      </div>
      <div className=" mt-5">
        <div className="row  mb-5">
          <div className="col-sm-12 col-md-5 col-lg-5 about-img2">
            <img src="./aboutImg/chairman.jpg" alt="img2" />
          </div>
          <div className=" mt-4 col-sm-12 col-md-7 col-lg-7">
            <div className=" text-center">
              <h1 className="text text-center">
                Message <span style={{ color: "#CD0000" }}> From Chairperson</span>
              </h1>
              <img style={{ width: "60%", height: "100%" }} src="line.png" alt="line" />
              <div className="text-1 mt-5">
                <p>
                  "Dear Friends, It is my privilege to welcome you as the Founder of Seeing Hands Nepal, a massage clinic that employs therapists from the visually impaired community. When I was younger, the visually impaired in Nepal were only restricted to careers in education. Seeing Hands was created to provide more avenues of work for the visually impaired. Across our 2 clinics in Kathmandu, we employ a total of 15 employees, 12 of whom are visually impaired. In 2010, I decided to bring Seeing Hands Nepal into Kathmandu, as an extension of Seeing Hands (Pokhara). Being the capital city of Nepal, majority of the visually impaired community was located here. However, there was a major lack of employment opportunities. Seeing Hands allows members of the visually impaired commmunity to earn a livelihood, provide for their families and lead independent lives. With the help of Rob and Susan Ainley, the British Founders of Seeing Hands (Pokhara), we were able to obtain funding from UK
                  charities, and Seeing Hands Nepal was born. I established Seeing Hands Nepal as a social enterprise that is both financially sustainable and independent. At the time, there were no visually impaired social enterprises around but that served as motivation for me to leave a lasting impression on the world. "
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
