import React, { useEffect, useState } from "react";
import { GalleryAPI } from "../../API/GalleryAPI";
import { SRLWrapper } from "simple-react-lightbox";
import GridLoader from "../../Loaders/GridLoader";
const ImageGalleryDetail = () => {
  const [allImages, setAllImages] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    GalleryAPI.getAllImage()
      .then((res) => {
        setAllImages(res.data.data.images.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          Image<span style={{ color: "#CD0000" }}> Gallery </span>
        </h1>
        <img style={{ width: "45%" }} src="/line.png" alt="line" />
      </div>

      {loading ? (
        <GridLoader item={3} row={3} />
      ) : (
        <SRLWrapper>
          <div className="gx-3 row mt-5 mb-5">
            {allImages?.map((image) => (
              <div className="col-lg-4 mb-5 col-md-6 col-sm-12 " key={image?.id}>
                <div className="card " style={{ width: "100%", padding: "1rem" }}>
                  <img src={imageBaseURL + "/" + image?.image_name} className="card-img-top " alt={image?.image_alt} />
                </div>
                <div className="card-body img-gallery-card-body">
                  <div className="card-body text-center" style={{ padding: "1rem" }}>
                    <p className="card-text img-gallery-card-text">{image?.image_alt}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SRLWrapper>
      )}
    </div>
  );
};

export default ImageGalleryDetail;
