import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function GridLoader({ item = 3, row = 1 }) {
  return (
    <>
      <div className="row mt-2 mb-4 g-3">
        {Array(row)
          .fill(null)
          ?.map(() =>
            Array(item)
              .fill(null)
              ?.map(() => (
                <div className={`col-${12 / item}`}>
                  <Skeleton width={"100%"} height={"15rem"} />
                </div>
              ))
          )}
      </div>
    </>
  );
}

export default GridLoader;
