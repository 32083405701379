import axios from "axios";

export const BookingAPI = {
  book: function (data) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/booking/create-booking`,
      data: data,
    });
  },
};
