import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="navbar-brand" onClick={() => navigate("/")}>
            <img src="/logo.png" alt="logo" />
          </div>
          <div className="brand-text" onClick={() => navigate("/")}>
            <span>SEEING HANDS NEPAL</span>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse nav-lst-itm" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li> */}
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  About
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/about">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/advisor">
                      Our Advisors
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/branches">
                      Branches
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/teamGallery">
                      Our Team
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/booking">
                  Booking
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faq's">
                  FAQs
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Gallery
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/imageGallery">
                      Image Gallery
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/videoGallery">
                      Video Gallery
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/local-support">
                  Local Support
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>

              <li className="nav-item navbar-donate">
                <a className="nav-link" href="https://shorturl.at/jlDZ9" target="_blank">
                  Support
                </a>
              </li>
              {/* <li className="nav-item">
                <Link to="/studioApartment">
                  <button type="button" class="btn btn-outline-danger">
                    Studio Apartment
                  </button>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
