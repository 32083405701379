import React from "react";
import Banner from "../Components/Banner";
import Gallery from "../Components/Gallery";
import Blogs from "../Components/Blogs";
import Feature from "../Components/Features";
import TypMass from "../Components/TypMass";
import MessFchair from "../Components/MessFchair";
import Branches from "../Components/Branches";

function Homepage() {
  return (
    <>
      <Banner />
      <Feature />
      <MessFchair />
      <TypMass />
      <Gallery />
      <Branches />
      <Blogs />
    </>
  );
}

export default Homepage;
