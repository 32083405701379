import React, { useState, useEffect } from "react";
import { BookingAPI } from "../API/BookingAPI";
import { BranchAPI } from "../API/BranchAPI";
import { ServiceAPI } from "../API/ServiceAPI";
import { countries } from "../fakeData/Countries";
import { validateRequired } from "../helpers/validators";
import { lengthRequired } from "../helpers/validators";
import { fireErrorModal, fireSuccessModal } from "../providers/sweetalert";

const initialState = {
  branch_id: "",
  service_id: "",
  time: "",
  date: "",
  datetime: "",
  name: "",
  email: "",
  phone_no: "",
  number: "",
  message: "",
};

const Booking = () => {
  const [booking, setBooking] = useState(false);

  const [allBranches, setAllBranches] = useState([]);
  const [allServices, setAllServices] = useState([]);

  const [bookingData, setBookingData] = useState(initialState);

  const handleBookingData = (e) => {
    setBookingData({ ...bookingData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    BranchAPI.getAll()
      .then((res) => {
        setAllBranches(res.data.data.branches.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
    ServiceAPI.getAll()
      .then((res) => {
        setAllServices(res.data.data.services.data);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);

  const submitBookingData = (e) => {
    e.preventDefault();

    if (
      !validateRequired(bookingData, [
        { key: "branch_id", message: "Please select clinic" },
        { key: "service_id", message: "Please select a service" },
        { key: "time", message: "Please select duration" },
        { key: "date", message: "Please select a date" },
        { key: "datetime", message: "Please select a time" },
        { key: "name", message: "Please enter your name" },
        { key: "email", message: "Please enter your email" },
        { key: "phone_no", message: "Please enter your contact number" },
        { key: "number", message: "Please enter no of people" },
      ])
    ) {
      return;
    }

    setBooking(true);
    BookingAPI.book(bookingData)
      .then((res) => {
        console.log("Booking SuccessFull");
        fireSuccessModal("Thank you for the booking. Your booking has been received. We will call you for the confirmation. !", "Thank You");
        setBookingData(initialState);
        setBooking(false);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal("Failed to book due to some error", "Error");

        setBooking(false);
      });
  };

  return (
    <div className="container mt-5">
      <header className="text-center">
        <h1 className="text text-center">
          Online <span style={{ color: "#CD0000" }}> Booking Form </span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </header>
      <body>
        <div className="text-1 mt-5">
          <p>This Clinic is open 7 days a week and advance booking is recommended, especially in high season, and for groups of more than 5 people.</p>
          <p>We have a fixed price policy but can offer some discounts for large groups or treatment packages for more than 5 people.</p>
          <p>To make a booking enquiry online, please using form below, selecting the clinic you wish to visit with details of how many people there are in your party and preferred dates and times. We will reply confirming availability and cost.</p>
        </div>

        <div className="row mt-5 mb-5 ">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="bookingImg ">
              <img src="./bookingImg/booking1img.png" alt="img2" />
            </div>
          </div>
          <form className="mt-4 col-lg-6 col-md-6 col-sm-12">
            <fieldset>
              <div>
                <div className="bookPlace mb-3">
                  <input type="text" class="form-control" placeholder="Name" id="exampleInputEmail1" aria-describedby="emailHelp" name="name" value={bookingData?.name} onChange={handleBookingData} />
                </div>
                <div className="bookPlace mb-3">
                  <input type="email" class="form-control" placeholder="Email" id="exampleInputEmail1" aria-describedby="emailHelp" name="email" value={bookingData?.email} onChange={handleBookingData} />
                </div>
                <div className="bookPlace  mb-3">
                  <input type="email" class="form-control" placeholder="Phone Number" id="exampleInputEmail1" aria-describedby="emailHelp" name="phone_no" value={bookingData?.phone_no} onChange={handleBookingData} />
                </div>
                <div className="bookPlace  mb-3">
                  <input type="date" class="form-control" placeholder="Select Date" id="exampleInputEmail1" aria-describedby="emailHelp" name="date" value={bookingData?.date} onChange={handleBookingData} />
                </div>
                <div className="bookPlace  mb-3">
                  <input type="time" class="form-control" placeholder="Select Time" id="exampleInputEmail1" aria-describedby="emailHelp" name="datetime" value={bookingData?.datetime} onChange={handleBookingData} />
                </div>

                <div className="bookPlace  mb-3">
                  <input type="number" class="form-control" placeholder="No of person" id="exampleInputEmail1" aria-describedby="emailHelp" name="number" value={bookingData?.number} onChange={handleBookingData} />
                </div>

                <div className=" mb-3">
                  <select class="form-select" aria-label="Default select example" name="service_id" value={bookingData?.service} onChange={handleBookingData}>
                    <option value="">Select Massage Type</option>
                    {allServices.map((service) => (
                      <option value={service?.id} key={service?.id}>
                        {service?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" mb-3">
                  <select class="form-select" aria-label="Default select example" name="time" value={bookingData?.time} onChange={handleBookingData}>
                    <option value="">Select Time</option>
                    <option>60 Mins</option>
                    <option>90 Mins</option>
                    <option>120 Mins</option>
                  </select>
                </div>
              </div>

              <div className=" mb-3">
                <select class="form-select" aria-label="Default select example" name="branch_id" value={bookingData?.branch} onChange={handleBookingData}>
                  <option value="">Select Clinic</option>
                  {allBranches.map((branch) => (
                    <option value={branch?.id} key={branch?.id}>
                      {branch?.branch_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" mb-3">
                <textarea className="form-control" rows={3} placeholder="Remarks" id="exampleInputEmail1" aria-describedby="emailHelp" name="message" value={bookingData?.message} onChange={handleBookingData}></textarea>
              </div>

              <div className="d-grid">
                <button style={{ backgroundColor: "#1c1b9b" }} type="submit" className=" btn btn-primary" onClick={submitBookingData} disabled={booking}>
                  {booking ? "Booking" : "Book"}
                </button>
              </div>
            </fieldset>
          </form>
          <div className="text-center mt-5">
            <h1 className="text text-center">
              Incase of any confusion, Please visit our
              <span style={{ color: "#CD0000" }}> FAQs</span> to get your answers
            </h1>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Booking;
