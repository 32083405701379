import React from "react";

function LocalSupport() {
  return (
    <div className="container mb-5 mt-5">
      <div className="text-center mb-4">
        <h1 className="text text-center">
          <span style={{ color: "#CD0000" }}> Local Support</span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </div>

      <p>Hi, I am Chiran Poudel, founder of Seeing Hands Nepal – a social enterprise providing training and employment opportunities in massage therapy for visually-impaired individuals. Currently, we operate 2 massage clinics in Kathmandu, employing 12 professionally-trained visually-impaired therapists and 3 administrative staffs.</p>

      <p>In Nepal, it can be difficult for the visually-impaired community to find employment and assimilate into society due to heavy social stigma. As a blind person myself, I faced similar struggles finding employment. For many years, the only job I could find was to play music at a restaurant. Even so, the working environment was not the most desirable due to heavy smoking and drinking by the restaurant patrons.</p>

      <p>Hence, with the establishment of Seeing Hands Nepal, I hope to bring more and better employment opportunities to the visually-impaired community, and empower them to lead happy and independent lives. At the same time, we see that in this profession we can offer our strong senses to society and contribute to other people’s wellbeing. Seeing Hand Nepal’s mission is to create a self-sustaining social enterprise run by the blind. We were pretty much there, before Covid19 hit.</p>

      <p>The pandemic has forced us to close two of our outlets and stop all services for 18 months to comply with government rules. Despite not having any income, we continued to support our staff financially during the pandemic as much as we could while restarting our business right when it was possible again; it also took some time for tourists to come back to Nepal. So as for any other tourism-dependent venture, our recovery took longer than we would have wished for.</p>

      <p>In order to keep supporting staff members during the pandemic and to open another clinic to re-employ the therapists afterwards, we took up a loan of the equivalent of 10,000 USD with an interest rate of 36%, as it was urgent and we had to take from different individuals. In the first few months of this year our services were in high demand again, with many customers recommending our services further. Hence we managed to repay 2,000 USD until now.</p>

      <p>The main goal right now is to repay our debt as fast as possible in order not to be weighed down by this burden anymore. We know we can do it, as we have faced a lot of challenges in the past, individually and as a community. Having your support to start afresh would be invaluable.</p>

      <p>Once we have repaid our loan we want to reopen other outlets again and to start training other fellows in order to extend income opportunities to more people.</p>

      <p>We sincerely thank you from the bottom of our hearts for your support. Any amount will get us closer to seeing the end of the tunnel. If you are not in a position to donate financially we would be very grateful for sharing this request and encouraging anyone coming to Nepal to use our services. To reciprocate your kindness, we would like to provide a special discount for all donors when you visit Seeing Hands Nepal. You can find us in Thamel, Chussya Galli and Kaldhara.In order to support us, please scan the QR code given below.</p>

      <img
        src="/QR.png"
        style={{
          display: "block",
          margin: "auto",
        }}
      />

      <p>
        <b>
          With love,
          <br /> Chiran Poudel and the team.
        </b>
      </p>
    </div>
  );
}

export default LocalSupport;
