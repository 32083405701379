import React, { useEffect, useState } from "react";
import { GalleryAPI } from "../../API/GalleryAPI";
import { SRLWrapper } from "simple-react-lightbox";
import GridLoader from "../../Loaders/GridLoader";
import { useNavigate } from "react-router-dom";
const ImageGallery = () => {
  const [allAlbums, setAllAlbums] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    GalleryAPI.getAllAlbums()
      .then((res) => {
        setAllAlbums(res.data.data.albums.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          Image<span style={{ color: "#CD0000" }}> Gallery </span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </div>

      {loading ? (
        <GridLoader />
      ) : (
        <div class="row my-5">
          {allAlbums?.slice(0, 3).map((album) => {
            return (
              <div className="col-lg-4 albumn-card" onClick={() => navigate(`/imageGallery/${album?.id}`)}>
                <div class="card typ-of-mass">
                  <div className="mass-typ-img">
                    <img src={imageBaseURL + "/" + album?.images[0]?.image_name} class="card-img-top" alt={"Albumn Image"} />
                  </div>
                  <div class="card-body py-2 px-3 mess-typ-bdy">
                    <p>{album?.name}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
