import { Routes, Route } from "react-router-dom";
import About from "../pages/About";
import OurAdvisor from "../pages/OurAdvisor";
import Blog from "../pages/Blog/Blog";
import BlogDescription from "../pages/Blog/BlogDescription";
import Booking from "../pages/Booking";
import Contact from "../pages/Contact";
import Faq from "../pages/Faq";
import ImageGalleryDetail from "../pages/Gallery/ImageGalleryDetail";
import ImageGallery from "../pages/Gallery/ImageGallery";
import TeamGallery from "../pages/Gallery/TeamGallery";
import VideoGallery from "../pages/Gallery/VideoGallery";
import Homepage from "../pages/Homepage";
import StudioApartment from "../pages/StudioApartment";
import BranchPage from "../pages/BranchPage";
import Scrolltotop from "../helpers/ScrollToTop";
import LocalSupport from "../pages/LocalSupport";

const AllRoutes = () => {
  return (
    <>
      <Scrolltotop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/advisor" element={<OurAdvisor />} />
        <Route path="/branches" element={<BranchPage />} />

        <Route path="booking" element={<Booking />} />
        <Route path="/faq's" element={<Faq />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogDescription />} />

        <Route path="/local-support" element={<LocalSupport />} />
        <Route path="teamGallery" element={<TeamGallery />} />
        <Route path="/imageGallery" element={<ImageGallery />} />
        <Route path="/imageGallery/:albumnId" element={<ImageGalleryDetail />} />
        <Route path="/videoGallery" element={<VideoGallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/studioApartment" element={<StudioApartment />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
