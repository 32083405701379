import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const FaqLoader = () => {
  return (
    <div>
      <div className="row">
        <div className="col-12 g-5 my-3">
          <Skeleton width={"100%"} height={"5rem"} />
          <Skeleton width={"100%"} height={"5rem"} />

          <Skeleton width={"100%"} height={"5rem"} />
          <Skeleton width={"100%"} height={"5rem"} />
        </div>
      </div>
    </div>
  );
};
