import React from "react";

const Features = () => {
  return (
    <>
      <div className="feature-sec">
        <div className="container">
          <div className="row">
            <div className="col-4 fetr-icm-txt">
              <i class="fa-solid fa-calendar-days"></i>
              <p>13+</p>
              <p>Years of Services</p>
            </div>
            <div className="col-4 fetr-icm-txt">
              <i class="fa-solid fa-user"></i>
              <p>15+</p>
              <p>No. of Employees</p>
            </div>
            <div className="col-4 fetr-icm-txt">
              <i class="fa-solid fa-people-group"></i>
              <p>
                {" "}
                2000+
                <p />
                <p />
                Massages Completed
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
