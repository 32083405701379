import React, { useEffect, useState } from "react";
import { ContactAPI } from "../API/ContactAPI";
import { validateRequired } from "../helpers/validators";
import { ContactLoader } from "../Loaders/ContactLoader";
import { fireErrorModal, fireSuccessModal } from "../providers/sweetalert";

const initialState = {
  country: "Nepal",
  name: "",
  email: "",
  phone_no: "",
  message: "",
};

const Contact = () => {
  const [contactData, setContactData] = useState(initialState);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);

  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    setLoading(true);
    ContactAPI.get()
      .then((res) => {
        setContactInfo(res.data.data.contactus);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  const handleContactData = (e) => {
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const submitContactData = (e) => {
    e.preventDefault();

    if (
      !validateRequired(contactData, [
        { key: "country", message: "Please select a country" },
        { key: "name", message: "Please enter your name" },
        { key: "email", message: "Please enter your email" },
        { key: "phone_no", message: "Please enter your contact number" },
        { key: "message", message: "Please enter a message" },
      ])
    ) {
      return;
    }

    setSending(true);
    ContactAPI.book(contactData)
      .then((res) => {
        fireSuccessModal("Your enquiry has been submitted successfully", "Thank You");
        setContactData(initialState);
        setSending(false);
      })
      .catch((e) => {
        console.log(e.message);
        fireErrorModal("Failed to submit enquiry", "Error");
        setSending(false);
      });
  };

  return (
    <div className="container mb-5 mt-5">
      <div className="text-center">
        <h1 className="text text-center">
          Get In <span style={{ color: "#CD0000" }}> Touch</span>
        </h1>
        <img style={{ width: "45%" }} src="/line.png" alt="line" />
      </div>
      <div className=" row " style={{ justifyContent: "space-between" }}>
        <div className="mt-5 col-lg-4 col-md-12  col-sm-12 contact">
          <h5 className="mb-4 contact">Contact Us</h5>
          {loading ? (
            <ContactLoader />
          ) : (
            <>
              <div className="contact">
                <p>Head Office: {contactInfo?.address}</p>
                <p>Tel.: {contactInfo?.phone_no}</p>
                {/* <p> Kaldhara Branch, Thamel</p> */}
                {/* <p> Mobible No.: + 977 - 1 - 4350780</p> */}
                <p>E-mail: {contactInfo?.email_address}</p>
              </div>
            </>
          )}

          <div className="contact-image  row mt-5">
            <button className="col-3" style={{ border: "0", backgroundColor: "white" }}>
              <a href={contactInfo?.facebook_link} target="_blank">
                <img className="mb-2 mt-2" src="/contact/facebook.png" alt="facebook" />
                <h5>Facebook</h5>
              </a>
            </button>
            <button className="col-3" style={{ border: "0", backgroundColor: "white" }}>
              <a href={contactInfo?.twitter_link} target="_blank">
                <img className="mb-2 mt-2" src="/contact/tiktok.png" alt="twitter" />
                <h5>Tiktok</h5>
              </a>
            </button>
            <button className="col-3" style={{ border: "0", backgroundColor: "white" }}>
              <a href={contactInfo?.insta_link} target="_blank">
                <img className="mb-2 mt-2" src="/contact/Instagram.png" alt="insta" />
                <h5>Instagram</h5>
              </a>
            </button>
            <button className="col-3" style={{ border: "0", backgroundColor: "white" }}>
              <a href={contactInfo?.youtube_link} target="_blank">
                <img className="mb-2 mt-2" src="/contact/whatsapp.png" alt="insta" />
                <h5>Whatsapp</h5>
              </a>
            </button>
          </div>
          <div className="mt-5 contact-text">
            <p>Please feel free to contact us at the above e-mail address for any website related content, queries,suggestion, and errors. Thank you for visiting www.seeinghandsnepal.org</p>
          </div>
        </div>

        <form className="mt-5 col-lg-6 col-sm-12 col-md-12">
          <fieldset>
            <div>
              <div className="bookPlace mb-3">
                <input type="text" className="form-control" id="floatingInput" placeholder="Your Name" name="name" value={contactData?.name} onChange={handleContactData} />
              </div>
              <div className="bookPlace mb-3">
                <input type="text" className="form-control" id="floatingInput" placeholder="Your Email" name="email" value={contactData?.email} onChange={handleContactData} />
              </div>
              <div className="bookPlace mb-3">
                <input type="text" className="form-control" id="floatingInput" placeholder="Contact No." name="phone_no" value={contactData?.phone_no} onChange={handleContactData} />
              </div>
              <div className="bookPlace  mb-3">
                <textarea rows="8" cols="40" type="text" className="form-control" id="floatingInput" placeholder="Message" name="message" value={contactData?.message} onChange={handleContactData} />
              </div>
            </div>

            <div className="d-grid">
              <button style={{ backgroundColor: "#1c1b9b" }} type="submit" className=" btn btn-primary" onClick={submitContactData} disabled={sending}>
                {sending ? "Sending..." : "Send"}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default Contact;
