import React, { useEffect, useState } from "react";
import { GalleryAPI } from "../../API/GalleryAPI";
import GridLoader from "../../Loaders/GridLoader";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const TeamGallery = () => {
  const [allTeams, setAllTeams] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState({});

  useEffect(() => {
    setLoading(true);
    GalleryAPI.getAllTeam()
      .then((res) => {
        setAllTeams(res.data.data.teammembers.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mt-4">
      <div className="text-center">
        <h1 className="text text-center">
          <span style={{ color: "#CD0000" }}> Team</span>
        </h1>
        <img style={{ width: "45%" }} src="line.png" alt="line" />
      </div>

      {loading ? (
        <GridLoader row={2} />
      ) : (
        <div className="g-3 row mt-5 mb-5">
          {allTeams?.map((team) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 bottom">
                <div className="card card-team-card">
                  <div className="mx-auto team-image">{team?.member_image ? <img style={{ backgroundColor: "lightgray" }} src={imageBaseURL + "/" + team?.member_image} className="card-img-top" alt={team?.image_alt} /> : <img style={{ backgroundColor: "lightgray" }} src={"main.png"} className="card-img-top" alt={team?.image_alt} />}</div>
                  <div className="card-body card-body-team ">
                    <div>
                      <h5 style={{ fontSize: "20px" }} className="text-center">
                        {team?.member_name}
                      </h5>
                      <p style={{ color: "black" }} className="text-center">
                        {team?.position}
                      </p>
                    </div>
                    <p className="card-text " style={{ height: "200px" }}>
                      {parse(team?.word_from_member.substring(0, 400) + " ..." || "")}
                    </p>
                    <div className="card-body text-end">
                      <button
                        type="button"
                        onClick={() => setSelectedTeam(team)}
                        className="btn  card-link  blog-link"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        style={{
                          textDecoration: "none",
                          color: "#CD0000",
                          border: "0",
                        }}>
                        Read More <span className="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="card" style={{ width: "100%" }}>
                <img
                  src={imageBaseURL + "/" + team?.member_image}
                  className="card-img-top"
                  alt={team?.image_alt}
                />
              </div>
            </div> */}
            </>
          ))}
        </div>
      )}

      <div className="g-3 row mt-5 mb-5">
        {/* Modal For ReadMORE button */}
        <div>
          <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-width ">
              <div className="modal-content">
                <div className="modal-header border-bottom-0">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body">
                  <div className="card card-team-card2">
                    <div className="mx-auto team-image2">
                      <img style={{ backgroundColor: "lightgray" }} src={imageBaseURL + "/" + selectedTeam?.member_image} className="card-img-top" alt="..." />
                    </div>
                    <div className="card-body card-body-team2 ">
                      <div>
                        <h5 style={{ fontSize: "20px" }} className="text-center">
                          {selectedTeam?.member_name}
                        </h5>
                        <p style={{ color: "black" }} className="text-center">
                          {selectedTeam?.position}
                        </p>
                      </div>
                      <p className="card-text ">{parse(selectedTeam?.word_from_member || "")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamGallery;
