import React from "react";

const MessFchair = () => {
  return (
    <>
      <div className="container all-section">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="chair-man-img">
              <img src="./aboutImg/chairman.jpg" alt="Chair-Man-Imgae"></img>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="text-center section">
              <h3>Message From Chairperson</h3>
              <img classname="divider-img" src="./divider.png" alt="divider" />
            </div>
            <div className="txt-sec">
              "Dear Friends, It is my privilege to welcome you as the Founder of Seeing Hands Nepal, a massage clinic that employs therapists from the visually impaired community. When I was younger, the visually impaired in Nepal were only restricted to careers in education. Seeing Hands was created to provide more avenues of work for the visually impaired. Across our 2 clinics in Kathmandu, we employ a total of 15 employees, 12 of whom are visually impaired. In 2010, I decided to bring Seeing Hands Nepal into Kathmandu, as an extension of Seeing Hands (Pokhara). Being the capital city of Nepal, majority of the visually impaired community was located here. However, there was a major lack of employment opportunities. Seeing Hands allows members of the visually impaired commmunity to earn a livelihood, provide for their families and lead independent lives. With the help of Rob and Susan Ainley, the British Founders of Seeing Hands (Pokhara), we were able to obtain funding from UK
              charities, and Seeing Hands Nepal was born. I established Seeing Hands Nepal as a social enterprise that is both financially sustainable and independent. At the time, there were no visually impaired social enterprises around but that served as motivation for me to leave a lasting impression on the world. "
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessFchair;
