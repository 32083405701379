import axios from "axios";

export const GalleryAPI = {
  getAllAlbums: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/album/get`,
      params: {
        limit: limit,
      },
    });
  },

  getAllImage: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/image/get`,
      params: {
        limit: limit,
      },
    });
  },

  getAllVideo: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/video/get`,
      params: {
        limit: limit,
      },
    });
  },

  getAllTeam: function (limit = 9999) {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/team/get`,
      params: {
        limit: limit,
      },
    });
  },
};
