import React, { useEffect, useState } from "react";
import { BlogAPI } from "../API/BlogAPI";
import GridLoader from "../Loaders/GridLoader";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [imageBaseURL, setImageBaseURL] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    BlogAPI.getAll()
      .then((res) => {
        setAllBlogs(res.data.data.blogs.data);
        setImageBaseURL(res.data.data.image_base_url);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="container all-section">
        <div className="text-center section">
          <h3>Blogs</h3>
          <img classname="divider-img" src="./divider.png" alt="divider" />
        </div>
        {loading ? (
          <GridLoader />
        ) : (
          <div class="row">
            {allBlogs?.slice(0, 3)?.map((blog) => (
              <div class="col-lg-4" key={blog?.id} onClick={() => navigate(`/blog/${blog?.id}`)}>
                <div class="card blog-crd-sec">
                  <img src={imageBaseURL + "/" + blog?.image} class="card-img-top" alt={blog?.image_alt} />
                  <div class="card-body">
                    <h4 class="card-text">{blog?.title}</h4>
                    <h5 class="card-text txt-sec">{parse(blog?.summary || "")}</h5>
                  </div>
                </div>
              </div>
            ))}

            <div className="text-center mt-4 ">
              <button type="button" class="btn out-lin-btn" onClick={() => navigate("/blog")}>
                View All Blogs
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Blogs;
