import axios from "axios";

export const ContactAPI = {
  book: function (data) {
    return axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/feedback/create-feedback`,
      data: data,
    });
  },

  get: function () {
    return axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/contactus/get`,
    });
  },
};
