import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ContactAPI } from "../API/ContactAPI";

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ContactAPI.get()
      .then((res) => {
        setContactInfo(res.data.data.contactus);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="foot-sec-dv">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="foot-logo">
                    <img src="/logo.png" alt=""></img>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="foot-txt">
                    About Seeing Hands
                    <br />
                    Seeing Hands is a social enterprise providing training and employment opportunities in massage therapy for visually impaired people. We operate 2 massage clinics across Nepal, each employing teams of professionally-trained blind therapists who provide massage to visiting guests. Part of the fees they earn is used to fund the training and employment of more blind masseurs so the organization can grow and develop. Over 25 blind individuals have now benefited, and our professional sports-style massage services are well reputed, with excellent ratings on Tripadvisor and in guidebooks.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 foot-list ">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>

                      <li>
                        <Link to="/local-support">Local Support</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 foot-list">
                    <ul>
                      <li>
                        <Link to="/imageGallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/booking">Booking</Link>
                      </li>
                      <li>
                        <Link to="/faq's">FAQs</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 foot-list foot-social">
                    <ul>
                      <li>
                        <a target="_blank" href={contactInfo?.facebook_link}>
                          <i class="fa-brands fa-facebook-f"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={contactInfo?.twitter_link}>
                          <i class="fa-brands fa-tiktok"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={contactInfo?.insta_link}>
                          <i class="fa-brands fa-instagram"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href={contactInfo?.youtube_link}>
                          <i class="fa-brands fa-whatsapp"></i>{" "}
                        </a>
                      </li>
                    </ul>

                    <ul>
                      <li>
                        <h5 className="text-light mb-2">Associated with</h5>
                        <a target="_blank" href="https://namotreks.com/.">
                          <img src="/hnblogo.JPG" alt="" className="img-fluid"></img>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
